
import { defineComponent, onBeforeMount, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { ApiBase, ApiShipments } from "@/core/api";
import mixin from "@/mixins";
import _ from "lodash";
import { useI18n } from "vue-i18n";
import {
  commonBackToList,
  formatDateTime,
  setModuleBCN,
} from "@/core/directive/function/common";

export default defineComponent({
  name: "transport-management-shipment-log",
  components: {},
  setup() {
    const { t } = useI18n();

    const route = useRoute();
    const router = useRouter();
    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const loading = ref<boolean>(false);
    const formRef = ref<null | HTMLFormElement>(null);
    const submitButton = ref<HTMLElement | null>(null);

    const formData = ref({});
    const options = ref({
      log_type: [],
      system_user: [],
    });

    const rules = ref({
      // status: [
      //   {
      //     required: true,
      //     message: "Status is required",
      //     trigger: "change",
      //   },
      // ],
    });

    const itemTable = ref([]);

    const getTypeStatus = (val) => {
      const status = new Map([
        ["1", t("shipments.logTypeStatusUpdate")],
        ["2", t("shipments.logTypeDataModification")],
      ]);
      return status.get(String(val));
    };

    const getShowInfo = () => {
      ApiShipments.getShipmentLogs({ shipment_id: route.params.shipment_id })
        .then(({ data }) => {
          // formData.value = data.data;
          itemTable.value = data.data.items;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const getUserName = (val) => {
      const arr: any = options.value.system_user.filter((item: any) => {
        return item.value == val;
      });
      return arr.length == 0 ? "" : arr[0].label;
    };

    const getUserSourceData = async () => {
      const { data } = await ApiBase.getUserSourceData({
        max_item: "all",
      });
      if (data.code == 0) {
        options.value.system_user = data.data;
      }
    };

    const getFromInfo = async () => {
      loading.value = true;
      Promise.all([getShowInfo(), getUserSourceData()])
        .then(() => {
          loading.value = false;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid) => {
        if (valid) {
          if (loading.value) return;
          loading.value = true;
          if (submitButton.value) {
            submitButton.value.setAttribute("data-kt-indicator", "on");
          }
          ApiShipments.updateShipment(formData.value)
            .then(({ data }) => {
              loading.value = false;
              submitButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                showFormSubmitSuccessMsg();
              } else {
                showServerErrorMsg(data);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const backToList = () => {
      commonBackToList(router, "/transport-management/shipments");
    };

    onBeforeMount(() => {
      getFromInfo();
    });

    onMounted(() => {
      setModuleBCN(t, route, router);
    });

    return {
      t,
      formatDateTime,
      loading,
      options,
      formData,
      formRef,
      rules,
      itemTable,
      submitButton,
      getUserName,
      getTypeStatus,
      getFromInfo,
      submit,
      backToList,
    };
  },
});
